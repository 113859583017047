import type { Clerk, VerificationStatus } from '@clerk/types';

declare global {
  interface Window {
    Clerk?: Clerk;
  }
}

export const CLERK_STATUSES: VerificationStatus[] = [
  'unverified',
  'verified',
  'transferable',
  'failed',
  'expired',
];
