export const COLORS = {
  slate: {
    100: '#E4F6F9',
    200: '#E1E6EE',
  },
  stone: {
    300: '#CBC8C7',
    500: '#72747A',
    900: '#36393F',
  },
  red: {
    700: '#DA8266',
    800: '#A44628',
  },
  yellow: {
    500: '#EDC170',
  },
  green: {
    800: '#3D6134',
    700: '#456B3C',
  },
  blue: {
    50: '#F4F7FA',
    100: '#EAF0FB',
    700: '#4569C7',
  },
  purple: {
    300: '#CBD5F0',
    400: '#AEBEE6',
    700: '#7474B5',
  },
} as const;

export const chartColors = {
  blue: {
    200: '#D5E3F7',
    300: '#B8D1F4',
    400: '#92B5EC',
    500: '#6B98E4',
    600: '#5580DB',
    800: '#3857B4',
    900: '#2B449D',
  },

  yellow: {
    200: '#FAE9CC',
    300: '#F4D9A8',
    400: '#F1CD89',
    500: '#EDC170',
    600: '#D4A850',
    700: '#BC9340',
    800: '#A47E30',
  },

  purple: {
    200: '#E2E7F5',
    500: '#9096CC',
    600: '#5D5F9E',
    800: '#4A4A8C',
    900: '#363666',
  },

  green: {
    200: '#C5D4C2',
    400: '#759A6C',
    500: '#588150',
    600: '#4B7243',
    700: '#456B3C',
    900: '#2A4B21',
  },

  orange: {
    300: '#F3C4A4',
    400: '#E8A885',
    500: '#DB8B65',
    600: '#C67046',
    700: '#B85A35',
  },

  teal: {
    400: '#5BA7A7',
    500: '#458F8F',
    600: '#367777',
    700: '#2A6060',
  },

  stone: {
    400: '#9A989A',
    600: '#595B63',
    700: '#484A52',
    800: '#3F4147',
  },
} as const;

export const chartColorArray = [
  chartColors.blue[500],
  chartColors.purple[600],
  chartColors.green[500],
  chartColors.orange[500],
  chartColors.teal[500],
  chartColors.purple[800],
  chartColors.green[700],
  chartColors.orange[600],
  chartColors.teal[600],
  chartColors.blue[400],
  chartColors.purple[500],
  chartColors.green[400],
  chartColors.orange[400],
  chartColors.teal[400],
  chartColors.blue[600],
  chartColors.green[600],
  chartColors.orange[700],
  chartColors.teal[700],
  chartColors.blue[800],
  chartColors.purple[900],
  chartColors.green[900],
  chartColors.orange[300],
  chartColors.stone[400],
  chartColors.blue[300],
  chartColors.purple[200],
  chartColors.green[200],
  chartColors.stone[600],
  chartColors.blue[200],
] as const;
