import { chartColors } from '@/constants/chartColorScheme';
import type { LocationType } from '@/utils/locationClassifier';

export const getBaseUrl = () => {
  if (process.env.NEXT_PUBLIC_APP_URL) {
    return process.env.NEXT_PUBLIC_APP_URL;
  }

  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  return 'http://localhost:3000';
};

export const getSessionToken = async () => {
  if (!window.Clerk?.session) return null;
  return (
    (await window?.Clerk?.session?.getToken({
      template: 'FileUpload',
    })) ?? null
  );
};

export const validateZipCode = (zip: string): boolean => {
  return /^\d{5}$/.test(zip.trim());
};

export const getLocationType = (location: string): LocationType => {
  if (location.includes(' DMA')) return 'DMA';
  if (/^[A-Z]{2}$/.test(location)) return 'STATE';
  if (validateZipCode(location)) return 'ZIPCODE';
  return 'UNKNOWN';
};

export const cleanValue = (value: string | null | undefined) => {
  return (
    value
      ?.replace(/"\[\]"/g, '')
      .replace(/[[\]']/g, '')
      .trim() || ''
  );
};

export const clearArray = (array: string[]) => {
  return array.filter((item) => item !== '');
};

const interpolate = (
  value: number,
  min1: number,
  max1: number,
  min2: number,
  max2: number,
) => {
  return min2 + ((value - min1) * (max2 - min2)) / (max1 - min1);
};

export const getColorBasedOnImpressions = (
  impressions: number,
  minImpressions: number,
  maxImpressions: number,
) => {
  const mapColor = chartColors.green;
  if (impressions === minImpressions && impressions === maxImpressions) {
    return mapColor[500];
  }

  const normalized = interpolate(
    impressions,
    minImpressions,
    maxImpressions,
    0,
    1,
  );

  if (normalized <= 0.2) {
    return mapColor[200];
  }
  if (normalized <= 0.4) {
    return mapColor[400];
  }
  if (normalized <= 0.6) {
    return mapColor[500];
  }
  if (normalized <= 0.8) {
    return mapColor[600];
  }
  return mapColor[700];
};
