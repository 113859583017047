import { useAuth, useClerk, useSignIn, useUser } from '@clerk/nextjs';
import type { UserResource, VerificationStatus } from '@clerk/types';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import { Route } from '@/constants/routes';
import { getBaseUrl } from '@/utils/Helpers';

/**
 * Props for {@link useClerkVerification}.
 */
interface UseClerkVerificationProps {
  /**
   * The current status of the Clerk authentication process.
   */
  clerkStatus: VerificationStatus | null;

  /**
   * The newly created session identifier used to set the active session.
   */
  createdSessionId: string | null;
}

/**
 * Custom hook that verifies a user's email link, activates their session,
 * and sets an organization if applicable. If any errors occur during this
 * process, Sentry will capture them.
 *
 * @param {UseClerkVerificationProps} props - The properties to drive the Clerk verification.
 * @param {VerificationStatus | null} props.clerkStatus - The current status of the Clerk authentication process.
 * @param {string | null} props.createdSessionId - The newly created session identifier used to set the active session.
 *
 * @returns {void} - This hook does not return anything directly.
 */
export const useClerkVerification = ({
  clerkStatus,
  createdSessionId,
}: UseClerkVerificationProps): void => {
  const { handleEmailLinkVerification } = useClerk();
  const { setActive } = useSignIn();
  const { user } = useUser();
  const { orgId } = useAuth();

  useEffect(() => {
    //  Sets the active organization for the current session.
    const setActiveOrganization = async (
      sessionId: string,
      currentUser: UserResource,
    ) => {
      const { data: memberships } =
        await currentUser.getOrganizationMemberships();

      if (memberships.length > 0) {
        // We're naively setting the first org as the active org
        await setActive?.({
          session: sessionId,
          organization: memberships[0]?.organization.id,
        });
      }
    };

    /**
     * Activates the current session and optionally sets the active organization
     * if none is currently active.
     */
    const activateSession = async (
      sessionId: string,
      currentUser: UserResource,
    ) => {
      await setActive?.({ session: sessionId });

      // If there is no orgId in the session, set the active organization
      if (!orgId) {
        await setActiveOrganization(sessionId, currentUser);
      }
    };

    /**
     * Handles the verification of the user's email link. If the user's status
     * is "verified" and a new session is created, set that session active.
     * If an organization is available, set that as the active organization.
     */
    const handleVerification = async () => {
      await handleEmailLinkVerification({
        redirectUrl: `${getBaseUrl()}${Route.FactorOne}`,
        redirectUrlComplete: `${getBaseUrl()}${Route.Dashboard}`,
      });

      const canActivateSession =
        clerkStatus === 'verified' && createdSessionId && setActive && user;

      if (canActivateSession) {
        await activateSession(createdSessionId, user);
      }
    };

    handleVerification().catch((err) => {
      Sentry.captureException(err, {
        extra: {
          userId: user?.id,
          clerkStatus,
          context: 'sign_in_verification',
        },
      });
    });
  }, [
    handleEmailLinkVerification,
    setActive,
    createdSessionId,
    clerkStatus,
    user,
    orgId,
  ]);
};
